<template>
  <CContainer class="d-flex align-items-center justify-content-center min-vh-100">
    <CRow class="d-flex align-items-center justify-content-center">
      <CCol md="12">
        <CCardGroup>
          <CCard class="p-4">
            <CCardBody>
              <CForm>
                <h1>Login</h1>
                <p class="text-muted">Ingresa a tu cuenta</p>
                <CInput
                  placeholder="Usuario"
                  autocomplete="username email"
                  v-model='emailField'
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  placeholder="Contraseña"
                  type="password"
                  autocomplete="curent-password"
                  v-model='password'
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <CRow>
                  <CCol col="6">
                    <CButton color="primary" class="px-4" @click="loginUser">Ingresar</CButton>
                  </CCol>
                  <CCol col="6" class="text-right">
                    <CButton color="link" class="px-0" @click="startResetPass">Recuperar contraseña</CButton>
                  </CCol>
                </CRow>
              </CForm>
              <div class="alerta-custom" v-show="alert.show" :style="'color: ' + alert.type">
                {{alert.message}}
              </div>
            </CCardBody>
          </CCard>
          <CCard
            color="primary"
            text-color="white"
            class="text-center py-5 d-md-down-none"
            style="width:44%"
            body-wrapper
          >
            <img :src="require('@/assets/img/logometavsec.png')" width="100%">
            <CButton
              color="primary"
              class="active mt-3"
							@click="$router.push({ name: 'Register' })"
            >
              Regístrate Ahora!
            </CButton>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { login, reestablecepass } from '@/helpers/api/user'
import {email} from '@/helpers/validators'

export default {
  name: 'Login',
  data(){
    return{
      emailField: '',
      password: '',
      alert: {
        show: false,
        message: '',
        type: 'black'
      }
    }
  },
  async mounted(){
    const token = await localStorage.getItem('userToken')
    if(token){
      localStorage.removeItem('userToken')
      location.reload()
    }
  },
  methods: {
		email,
    loginUser(){
      const self = this
      login(this.emailField, this.password)
        .then(function(resp){
          self.$router.push({ name: 'Dashboard' })
        })
        .catch(function(error){
          self.alert = {
            message: error.message,
            show: true,
            type: 'red'
          }
          setTimeout(function() {
            self.alert = {
              show: false,
              message: '',
              type: 'black'
            }
          }, 4000);
        })
    },
		startResetPass(){
			if(this.email(this.emailField)){
				this.resetPass()
			}
			else{
				this.$store.commit('addToast', {
					color: 'warning',
					header: 'Error',
					message: this.$i18n.t('enterEmailForPassReset')
				})
			}
		},
		async resetPass(){
			let completed = false
			try{
				const data = {
					email: this.emailField
				}
				await reestablecepass(data)
				completed = true
			}
			catch(e){
				completed = false
			}

			this.$store.commit('addToast', {
				color: completed ? 'success':'warning',
				header: completed ? this.$i18n.t('okResetPassTitle'):'Error',
				message: completed ? this.$i18n.t('okResetPass'):this.$i18n.t('errorMessage')
			})
		}
  }
}
</script>
<style type="text/css" media="screen">
  .alerta-custom{
    font-size: 10px;
  }  
</style>